import axios from "axios";
import {ISettings} from "@/interfaces/ISettings";

export class LpApiService {
    static domain = process.env.NODE_ENV === "production" ? "https://upload.liveportrait.com" : "http://0.0.0.0:8887";

    static async saveSettings(values: any) {
        await axios.post(`${(LpApiService.domain)}/metric/settings`, values)
    }

    static async getSetting(): Promise<ISettings> {
        const response = await axios.get(`${(LpApiService.domain)}/metric/settings`)
        // response.data.pfn_website_enabled = response.data.pfn_website_enabled === 'true'
        return response.data as ISettings
    }
}
